<template>
  <div class="px-2">
    <b-card class="mt-1" no-body>
      <b-card-header>
        <div class="d-flex align-items-center">
          <feather-icon class="mr-1" icon="FilterIcon" />
          {{ $t("Filters") }}
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="4">
            <p style="margin-bottom: 0.5rem">{{ $t("Repair Add Time") }}</p>
            <flat-pickr
              v-model="filterValues.add_time"
              :config="{
                wrap: true,
                time_24hr: true,
                mode: 'range',
                locale: $i18n.locale === 'cn' ? 'zh' : 'en',
              }"
              class="form-control"
            />
          </b-col>
          <b-col cols="4">
            <p style="margin-bottom: 0.5rem">{{ $t("Repair Type") }}</p>
            <v-select
              v-model="filterValues.repair_type_name"
              :options="filterOptions.repair_type_name"
              :placeholder="$t('No filter')"
              class="w-100"
            />
          </b-col>
          <b-col cols="4">
            <p style="margin-bottom: 0.5rem">{{ $t("Repair Status") }}</p>
            <v-select
              v-model="filterValues.status"
              :options="filterOptions.status"
              :placeholder="$t('No filter')"
              class="w-100"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <p style="margin-bottom: 0.5rem">{{ $t("Repair PersName") }}</p>
            <v-select
              v-model="filterValues.persname"
              :options="filterOptions.persname"
              :placeholder="$t('No filter')"
              class="w-100"
            />
          </b-col>
          <b-col cols="4">
            <p style="margin-bottom: 0.5rem">{{ $t("Repair User") }}</p>
            <v-select
              v-model="filterValues.user_name"
              :options="filterOptions.user_name"
              :placeholder="$t('No filter')"
              class="w-100"
            />
          </b-col>
          <b-col cols="4">
            <p style="margin-bottom: 0.5rem">{{ $t("Emergency Degree") }}</p>
            <v-select
              v-model="filterValues.emergency_name"
              :options="filterOptions.emergency_name"
              :placeholder="$t('No filter')"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card class="mb-0" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="6"
          >
            <label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label style="margin: 0.5rem 0">{{ $t("Entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              class="d-flex align-middle justify-content-end h-100"
              style="padding: 0.3rem 0"
            >
              <download-excel
                :fetch="downloadData"
                :fields="exportExcelHeaders(tableColumns)"
                name="下载查询数据.xls"
                type="xls"
              >
                <b-button size="sm" variant="success">
                  <feather-icon class="mr-50" icon="DownloadIcon" />
                  {{ $t("Download") }}
                </b-button>
              </download-excel>
              <b-button
                size="sm"
                variant="info"
                class="ml-1"
                @click="refetchData"
              >
                <feather-icon class="mr-50" icon="RefreshCwIcon" />
                {{ $t("Refresh") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refRepairListTable"
        :busy="isBusy"
        :empty-text="$t('No Data')"
        :fields="tableColumns"
        :items="fetchRepairs"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        bordered
        class="text-center position-relative"
        hover
        primary-key="repair_id"
        responsive
        show-empty
        small
        striped
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>{{ $t("Loading") }}...</strong>
          </div>
        </template>
        <!-- Column: Index -->
        <template #cell(index)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.index + 1 }}
            </span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ filterOptions["status"][data.item.status] || "" }}
            </span>
          </div>
        </template>
        <!-- Column: Index -->
        <template #cell(actions)="data">
          <b-button
            v-b-modal:repair-list-print-modal
            class="btn-icon"
            size="sm"
            variant="warning"
            @click="printData = data.item"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
            cols="12"
            sm="6"
          >
            <span class="text-muted">
              {{
                `
                            ${$t("Show")}
                            ${dataMeta.from}
                            ${$t("to")}
                            ${dataMeta.to}
                            ${$t("of")}
                            ${total}
                            ${$t("Entries")}
                            `
              }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
            cols="12"
            sm="6"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              hide-goto-end-buttons
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>

      <print-modal :print_data="printData"></print-modal>
    </b-card>
  </div>
</template>

<script>
import { momentJs } from "@/libs/utils/moment";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import Ripple from "vue-ripple-directive";
import {
  computed,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import { showToast } from "@/libs/utils/showToast";
import { getRepairs, getRepairsOptions } from "@/api/front";
import PageTitle from "@/views/layout/PageTitle";
import { routerParams } from "@/libs/utils/routerParams";
import printModal from "./components/printModal";
import { t } from "@core/libs/i18n/utils";

export default {
  directives: {
    Ripple,
  },
  components: {
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
    printModal,
  },
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      filterOptions: {},
      filterValues: {
        status: null,
        add_time: momentJs().format(),
        repair_type_name: null,
        user_name: null,
        persname: null,
        emergency_name: null,
      },
      isEditRepairSidebarActive: false,
      refRepairListTable: null,
      perPage: 10,
      searchQuery: "",
      tableColumns: [
        {
          label: "#",
          key: "index",
        },
        {
          label: "维修类型",
          key: "repair_type_name",
          sortable: true,
        },
        {
          label: "报修人",
          key: "persname",
          sortable: true,
        },
        {
          label: "报修电话",
          key: "link_phone",
        },
        {
          label: "报修时间",
          key: "add_time",
        },
        {
          label: "紧急程度",
          key: "emergency_name",
          sortable: true,
        },
        {
          label: "报修说明",
          key: "position_memo",
          sortable: true,
        },
        {
          label: "接单人",
          key: "user_name",
          sortable: true,
        },
        {
          label: "维修时间",
          key: "rev_time",
          sortable: true,
        },
        {
          label: "完成时间",
          key: "complete_time",
          sortable: true,
        },
        {
          label: "维修状态",
          key: "status",
          sortable: true,
        },
        {
          label: "维修结果",
          key: "rev_result",
          sortable: true,
        },
        {
          label: "打印",
          key: "print_count",
          sortable: true,
        },
        {
          label: "操作",
          key: "actions",
        },
      ],
      total: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      isSortDirDesc: false,
      dataMeta: computed(() => {
        const localItemsCount = event.refRepairListTable
          ? event.refRepairListTable.localItems.length
          : 0;
        return {
          from:
            event.perPage * (event.currentPage - 1) + (localItemsCount ? 1 : 0),
          to: event.perPage * (event.currentPage - 1) + localItemsCount,
          of: event.total,
        };
      }),
      printData: {},
      isBusy: false,
      refreshDataInterval: null,
    });

    watch(
      [
        () => event.currentPage,
        () => event.perPage,
        () => event.isEditRepairSidebarActive,
        () => event.searchQuery,
      ],
      () => {
        if (!event.isEditRepairSidebarActive) {
          refetchData();
        }
      }
    );

    watch(
      event.filterValues,
      () => {
        refetchData();
      },
      "deep"
    );

    const refetchData = () => {
      event.refRepairListTable.refresh();
    };

    const fetchRepairs = (ctx, callback) => {
      event.isBusy = true;
      const filters = getFilters();
      const params = {
        project_id: event.project_id,
        pagesize: event.perPage,
        page: event.currentPage,
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        ...filters,
      };
      getList(params)
        .then((response) => {
          const { list, total } = response.data;
          callback(list);
          event.total = total;
          if (!total) {
            showToast(null, "提示", "未查询到数据");
          }
          event.isBusy = false;
        })
        .catch((error) => {
          showToast(error);
          event.isBusy = false;
        });
    };
    /**
     * 获取列表数据
     */
    const getList = async (params) => {
      return getRepairs({
        project_id: event.project_id,
        ...params,
      });
    };
    /**
     * 获取参数
     */
    const getOptions = () => {
      getRepairsOptions({
        project_id: event.project_id,
      })
        .then((response) => {
          event.filterOptions = response.data;
        })
        .catch((error) => {
          showToast(error);
        });
    };
    /**
     * 处理导出数据的头部
     * @param fields
     */
    const exportExcelHeaders = (fields) => {
      let headers = {};
      fields.map((item) => {
        if (item.key !== "index" && item.key !== "actions") {
          let header = t(item.label) + "(" + item.key + ")";
          headers[header] = item.key;
        }
      });
      return headers;
    };
    /**
     * 获取筛选条件
     */
    const getFilters = () => {
      let filters = {};
      for (const Key in event.filterValues) {
        let Value = event.filterValues[Key];
        if (!!Value) {
          filters[Key] = Value;
          if (Key === "add_time") {
            Value = Value.replaceAll(" 至 ", ",").replaceAll(" to ", ",");
            const timeArr = Value.split(",");
            const start = momentJs(timeArr[0])
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            const end = momentJs(timeArr[1] || timeArr[0])
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            filters[Key] = "(" + start + "," + end + ")";
          }
          if (Key === "status") {
            filters[Key] = 0;
            event.filterOptions.status.map((item, index) => {
              if (item === Value) {
                filters[Key] = index + "";
              }
            });
          }
        }
      }
      return filters;
    };
    /**
     * 下载数据
     * @returns {Promise<*>}
     */
    const downloadData = async () => {
      const filters = getFilters();
      const params = {
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        ...filters,
      };
      const res = await getList(params);
      if (!res.data.total) {
        showToast(null, "提示", "暂无数据可以下载");
      }
      return res.data.list;
    };

    const refreshDataIntervalFn = () => {
      if (event.refreshDataInterval) {
        clearInterval(event.refreshDataInterval);
        event.refreshDataInterval = null;
      }
      event.refreshDataInterval = setInterval(() => {
        refetchData();
      }, 1000 * 30);
    };

    onMounted(() => {
      getOptions();
      refreshDataIntervalFn();
    });
    return {
      ...toRefs(event),
      fetchRepairs,
      exportExcelHeaders,
      downloadData,
      refetchData,
      zh,
    };
  },
};
</script>

<style lang="scss">
/* .per-page-selector {
width: 90px;
}

.b-form-btn-label-control.dropdown.b-form-datepicker.form-control
label.form-control {
padding: 0.3rem 1rem;
} */
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker";
@import "@core/scss/vue/libs/vue-select";
</style>
