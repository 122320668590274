<template>
  <b-modal
    id="repair-list-print-modal"
    button-size="sm"
    cancel-title="取消"
    ok-title="打印"
    size="xl"
    title="打印预览"
    @ok="printAction"
  >
    <b-card-text>
      <div class="w-100 px-3">
        <b-row>
          <b-col cols="6">
            <small>此维修单</small>
            <small v-if="printData.print_count > 0" class="text-success"
              >已打印 {{ printData.print_count }} 次</small
            >
            <small v-else class="text-error">未打印</small>
          </b-col>
          <b-col class="text-right" cols="6">
            <b-form-checkbox
              :checked="hideData"
              switch
              @change="hideData = !hideData"
            >
              <label>隐藏数据</label>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-card class="p-2">
          <b-container class="text-center">
            <div style="margin: 0 auto; width: 940px">
              <div id="print-area" style="width: 940px">
                <table
                  class="printTable w-100 h-100"
                  style="border-collapse: collapse"
                >
                  <tr style="text-align: center">
                    <td
                      colspan="6"
                      style="height: 36px; font-size: 26px; font-weight: 600"
                    >
                      {{ $store.state.menus.current.params.project_name }}
                    </td>
                  </tr>
                  <tr style="text-align: center; height: 26px; font-size: 16px">
                    <td colspan="6">工程报修单</td>
                  </tr>
                  <tr style="text-align: center; height: 36px; font-size: 16px">
                    <td style="width: 15%; font-weight: 500">报修类型</td>
                    <td style="width: 25%">{{ printData.repair_type_name }}</td>
                    <td style="width: 15%; font-weight: 500"></td>
                    <td style="width: 18%; font-weight: 500">
                      {{ project_id === 68 ? "收费 | 免费" : "" }}
                    </td>
                    <td style="width: 12%; font-weight: 500">单号 :</td>
                    <td style="width: 15%; min-width: 200px">
                      <!--                      {{ momentJs(printData.add_time).format("YYYYMMDDHHmmss") }}-->
                      {{ printData.sid }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 36px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      客户名称
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.persname }}
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      报修人
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.contactor }}
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      联系电话
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.link_phone }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 36px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td
                      rowspan="2"
                      style="border: 1px solid #82868b; font-weight: 500"
                    >
                      报修位置
                    </td>
                    <td rowspan="2" style="border: 1px solid #82868b">
                      {{ printData.repair_addr }}
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      紧急程度
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.emergency_name }}
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      预约时间
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.repair_time }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 36px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      派单人
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.status == 0 ? "客户下单" : "管理员派单" }}
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      报修时间
                    </td>
                    <td style="border: 1px solid #82868b">
                      {{ printData.add_time }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 44px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      报修内容
                    </td>
                    <td
                      colspan="5"
                      style="border: 1px solid #82868b"
                      width="80"
                    >
                      {{
                        printData.position_memo +
                        " " +
                        (printData.repair_memo || "")
                      }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 44px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      维修结果
                    </td>
                    <td
                      colspan="5"
                      style="border: 1px solid #82868b"
                      width="80"
                    >
                      {{
                        printData.finish_time == 0
                          ? "未完成"
                          : printData.rev_result_meno || printData.rev_result
                      }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 44px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      所用材料和数量
                    </td>
                    <td
                      colspan="5"
                      style="border: 1px solid #82868b"
                      width="80"
                    >
                      {{ printData.spare_part_requisition }}
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 36px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td
                      rowspan="2"
                      style="border: 1px solid #82868b; font-weight: 500"
                    >
                      客户满意度
                    </td>
                    <td rowspan="2" style="border: 1px solid #82868b">
                      <div>
                        <b-icon
                          v-for="(item, index) in 5"
                          :key="index"
                          :icon="
                            index < printData.comment_star
                              ? 'star-fill'
                              : 'star'
                          "
                          class="mr-50 text-warning"
                          size="26"
                        ></b-icon>
                      </div>
                    </td>
                    <td
                      rowspan="2"
                      style="border: 1px solid #82868b; font-weight: 500"
                    >
                      维修人员
                    </td>
                    <td rowspan="2" style="border: 1px solid #82868b">
                      <span v-if="!hideData"> {{ printData.user_name }} </span>
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      到场时间
                    </td>
                    <td style="border: 1px solid #82868b">
                      <span v-if="!hideData"> {{ printData.rev_time }} </span>
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 36px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      回单时间
                    </td>
                    <td style="border: 1px solid #82868b">
                      <span v-if="!hideData">
                        {{
                          printData.hf_time ? momentFmt(printData.hf_time) : ""
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 44px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      备注
                    </td>
                    <td
                      colspan="5"
                      style="border: 1px solid #82868b"
                      width="80"
                    ></td>
                  </tr>
                  <tr
                    style="
                      text-align: center;
                      height: 44px;
                      font-size: 18px;
                      border: 1px solid #82868b;
                    "
                  >
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      客户签字
                    </td>
                    <td style="border: 1px solid #82868b">
                      <template v-if="printData.comment_sign_name">
                        <b-img
                          :src="printData.comment_sign_name"
                          alt="客户签字"
                          fluid
                          style="height: 40px"
                        ></b-img>
                      </template>
                    </td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      核实人员签字
                    </td>
                    <td style="border: 1px solid #82868b"></td>
                    <td style="border: 1px solid #82868b; font-weight: 500">
                      核实情况
                    </td>
                    <td style="border: 1px solid #82868b"></td>
                  </tr>
                  <tr
                    v-if="project_id === 68"
                    style="
                      text-align: center;
                      height: 35px;
                      font-size: 16px;
                      line-height: 35px;
                    "
                  >
                    <td style="width: 20%">第一联：工程部</td>
                    <td style="width: 20%">第二联：客服部</td>
                    <td style="width: 20%">第三联：财务部</td>
                    <td colspan="4" style="width: 40%"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-container>
        </b-card>
      </div>
    </b-card-text>
  </b-modal>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
import { momentFmt, momentJs } from "@/libs/utils/moment";
import { routerParams } from "@/libs/utils/routerParams";
import printJs from "print-js";

export default {
  name: "repairListPrintModal",

  props: {
    print_data: [Object],
  },
  setup(props) {
    const event = reactive({
      project_id: computed(() => {
        return parseInt(routerParams("project_id"));
      }),
      printData: computed(() => {
        return props.print_data;
      }),
      hideData: false,
    });
    const printAction = () => {
      console.log("准备打印");
      printJs({
        printable: "print-area",
        type: "html",
        // 继承原来的所有样式
        targetStyles: ["*"],
        maxWidth: 950,
        gridStyle: "color:grey;",
        style: "@page { size:auto;margin: 0.5cm 1cm 0cm 2cm; } ",
        scanStyles: false,
      });
    };
    onMounted(() => {});
    return {
      ...toRefs(event),
      momentJs,
      momentFmt,
      printAction,
    };
  },
};
</script>

<style scoped></style>
